import React from "react";
import { Box, Text, Anchor } from "grommet";
import styled from "styled-components";
import { textSwe } from "./textSwe";
import { textEng } from "./textEng";
import { MailComponentSwe } from "./mailComponentSwe";
import { MailComponentEng } from "./mailComponentEng";

const StyledBox = styled(Box)`
  margin-top: ${props => props.marginT};
  margin-bottom: ${props => props.marginB};
  `


export const NoAnswerCard = ({ person, size, language }) => {
    const textArray = language==="SWE"?textSwe:textEng;
    //const mailString = "mailto:" + person.email + "?subject=Riksdagen för framtiden&body=Är du villig att vara med om att fatta beslut för klimatet, genom att rösta ja till en motion om att uppdra åt regeringen att genomföra dessa tio punkter? Kan du motivera ditt svar?";
    const textSize = size === "xsmall" || size === "small" ? "small" : "medium";
    return (
        <Box width="70%" pad="medium" gap="small">
            {!person.hasAnswered && size === "xsmall" ?
                <Box align="end" pad="small">
                    <StyledBox marginT="8px" marginB="-24px" width="medium" background="#DE1A1A" pad="small">
                        <Text color="white" weight="bold" size="medium" textAlign="center">{textArray[0]}</Text>
                    </StyledBox>
                </Box> : <Box />
            }
            <Box margin={{ top: "14px" }} gap="small" width="large" background="indexGreen" pad="medium" round="xsmall">
                <Box justify="start" direction="row" gap="xsmall">
                    <Text size={textSize} color="darkGreen">{textArray[1]} <Text size={textSize} wordBreak="break-word" color="darkGreen" weight="bold">{person.email} <Text size={textSize} weight="normal" color="darkGreen">{textArray[2]}</Text></Text></Text>
                </Box>
                <Box width="100%" align="start" direction="row" gap="small">
                    <Text size={textSize} color="darkGreen" weight="bold">{textArray[3]}</Text>
                    <Text size={textSize} color="darkGreen">{textArray[4]}</Text>
                </Box>
                <Box width="100%" align="start" direction="row" gap="small">
                    <Text size={textSize} color="darkGreen" weight="bold">{textArray[5]}</Text>
                    <Box round="xsmall" pad={{ horizontal: "small", vertical: "xsmall" }} background="white" overflow={{ vertical: "scroll" }} height="102px">
{/*                        <Text size={textSize} color="darkGreen">
                        {textArray[6]}
                        </Text> */}
                        {language==="SWE"?<MailComponentSwe textSize={textSize}/>:<MailComponentEng textSize={textSize}/>}

                    </Box>
                </Box>
                {/*                <Box align="center">
                    <Anchor size={textSize} href={mailString}>{textArray[7]}</Anchor>
                </Box> */}

            </Box>
        </Box>
    )
}