import React,{useContext} from "react";
import {Box,ResponsiveContext} from "grommet"
import { LargeLayout } from "./elements/LargeLayout";
import { SmallLayout } from "./elements/SmallLayout";
import {useSelector} from "react-redux"

export const PersonPage = (props) => {
  const size = useContext(ResponsiveContext)
  const language = useSelector(state=> state.language.language);
  return (
    <Box>
{size==="xsmall" ||size==="small"?<SmallLayout props={props} size={size} language={language}/>: <LargeLayout props={props} size={size} language={language}/>}
</Box>
  );
};
