import React from "react";
import { Text, Box, Stack, Image } from "grommet";
import { HeaderCard } from "./headerCard";
//import { InfoCard } from "./elements/infoCard";
import { AnswerCard } from "./answerCard";
import { NoAnswerCard } from "./noAnswerCard";
import riksdagen2 from "../../icon/riksdagen2.jpg"

export const LargeLayout = ({props,size,language}) => {
    return(
    <Stack>
      <Box height="460px">
        <Image fill opacity="medium" src={riksdagen2} />
      </Box>
      {!props.hasAnswered ?
        <Box align="end" pad="small">
          <Box margin={{ top: "32px" }} width="medium" background="#DE1A1A" pad="small">
            <Text color="white" weight="bold" size="xlarge" textAlign="center">{language === "SWE"? "Har inte svarat!":"Has not answered!"}</Text>
          </Box>
        </Box> : <Box />
      }

      <Box>
        <Box width="25%" margin={{ top: "26px" }} background="#95BD8C" pad="medium">
          <Text color="lime" weight="bold" size="xlarge" textAlign="end">{props.name}</Text>
        </Box>
        <Box direction="row">
          <Box width="25%" align="end">
            <HeaderCard name={props.name} imageURL={props.imageURL} imageCaption={props.imageCaption} party={props.party} height="260px" />
          </Box>
          {props.hasAnswered ? <AnswerCard person={props} size={size} language={language} height="188px"/> : <NoAnswerCard person={props} size={size} language={language}/>}

        </Box>
      </Box>
    </Stack>
    )
}