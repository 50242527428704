import React from "react";
import { Text, Box, Stack, Image } from "grommet";
import { HeaderCard } from "./headerCard";
//import { InfoCard } from "./elements/infoCard";
import { AnswerCard } from "./answerCard";
import { NoAnswerCard } from "./noAnswerCard";
import riksdagen2 from "../../icon/riksdagen2.jpg"

export const SmallLayout = ({props,size,language}) => {
    return(
    <Stack>
        {props.hasAnswered ?      
        <Box height="300px">
        <Image fill opacity="medium" src={riksdagen2} />
      </Box>: 
           <Box height="500px">
        <Image fill opacity="medium" src={riksdagen2} />
      </Box>}

      
      <Box>
        <Box margin={{ top: "26px" }} background="#95BD8C" pad="medium">
          <Text color="lime" weight="bold" size="medium" textAlign="start">{props.name}</Text>
        </Box>
        <Box direction="row">
            <Box width="40%">
            <HeaderCard name={props.name} imageURL={props.imageURL} imageCaption={props.imageCaption} party={props.party} height="180px" />
            </Box>
          {props.hasAnswered ? <AnswerCard  person={props} size={size} language={language} height="134px"/> : <NoAnswerCard person={props} size={size} language={language}/>}

        </Box>
      </Box>
    </Stack>
    )
}