import React from "react"
import { Box, Image, Stack } from "grommet"
import { buildImageObj } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";

export const HeaderCard = ({ name, imageURL, imageCaption, party, height }) => {
    return (
        <Box pad={{vertical:"large",left:"large"}}>
            <Stack>
                <Box  direction="row" justify="between" fill>
                <Box />
                <Image margin={{right:"1rem"}} style={{maxHeight:height,maxWidth:"90%"}} src={imageURL} alt={imageCaption} />

                </Box>
                <Box  direction="row" justify="between" fill>
                    <Box />
                    <Box
                        height="55px"
                        width="55px"
                        background="#ffffff"
                        round="large"
                        align="center"
                        pad="11px"
                        margin="-10px"
                    >
                        <Image
                            fit="cover"
                            src={imageUrlFor(buildImageObj(party.image))
                                .url()}
                            alt={party.image.alt}
                        />


                    </Box>
                </Box>
            </Stack>
        </Box>
    )
}