import { graphql } from "gatsby";
import React from "react";
import { Grommet } from "grommet";
import { theme } from "../theme/theme";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/old/container";
import SEO from "../components/seo";
import {PersonPage} from "../components/personPage";
/* 
export const query = graphql`
query PersonPageQuery($id: String!) {
    person: sanityPerson(_id: {eq: $id }) {
        name
        id
          image{
            ...SanityImage
            alt
          }
          partier {
            abbreviation
          }
        
      
    }
}
`;
*/
export const query = graphql`
  query PersonTemplateQuery($id: String!) {
    person: sanityPerson(id: { eq: $id }) {
      id
      name
      surname
      answerText
      born
      constituency
      email
      gender
      hasAnswered
      imageCaption
      imageURL
      party {
        name
        abbreviation
        image{
          ...SanityImage
          alt
        }
      }
    }
  }
`;


const PersonTemplate = (props) => {
    const { data, errors } = props;
    const person = data && data.person;
    return (
      <Grommet theme={theme}>
        <Layout>
            {errors && <SEO title="GraphQL Error" />}
            {person && (
                <SEO
                title={person.name || "Untitled"}
                />
            )}
        {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {person && <PersonPage {...person}/>}
        </Layout>
        </Grommet>
    );
};

export default PersonTemplate;