import React from "react";
import { Box, Text } from "grommet";
import styled from "styled-components";
const StyledBox = styled(Box)`
  margin-top: ${props => props.marginT};
  `
export const AnswerCard = ({person, size, language,height}) => {
  
  
  const marginT = size==="xsmall"||size==="small"?"13px":"26px";
    return(
        <Box width="70%" pad="medium" gap="small" >
        <StyledBox marginT={marginT} pad="small" align="center" width="small" background="#95BD8C">
            <Text weight="bold" color="lime">
              {language === "SWE"?"Har svarat:":"Has answered:"}
            </Text>
        </StyledBox>
          <Box style={{maxHeight:height}} background="indexGreen" pad="small" width="large" overflow={{vertical:"scroll"}}>
          <Text color="darkGreen" >
            {language === "SWE"? person.answerText : person.answerText }
          </Text>
          </Box>
        </Box>
    )
}